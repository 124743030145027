import PropTypes from "prop-types";
import Router from "next/router";
import Link from "next/link";
import trans from "counterpart";
import { Stack, Link as MuiLink } from "@mui/material";
import {
  ID_SAFARIRESORT,
  ID_SAFARIRESORT_HOTEL,
  ID_HOLIDAYPARK,
  ID_DIERENBOS,
} from "../../../booking/constants";

const customerGeneralTermsVariations = {
  [ID_SAFARIRESORT]: {
    park: "resort.safari_resort",
    recron: "form.termsConditions.recronUrl.safari_resort",
    exploitatie: "resort.safari_resort",
    parkreglement: "form.termsConditions.parkreglementUrl.safari_resort",
    conditions: "form.termsConditions.generalTerms.safari_resort",
  },
  [ID_SAFARIRESORT_HOTEL]: {
    park: "resort.safari_hotel",
    recron: "form.termsConditions.recronUrl.safari_hotel",
    exploitatie: "resort.safari_resort",
    parkreglement: "form.termsConditions.parkreglementUrl.safari_hotel",
    conditions: "form.termsConditions.generalTerms.safari_hotel",
  },
  [ID_HOLIDAYPARK]: {
    park: "resort.lake_resort",
    recron: "form.termsConditions.recronUrl.lake_resort",
    exploitatie: "resort.bb",
    parkreglement: "form.termsConditions.parkreglementUrl.lake_resort",
    conditions: "form.termsConditions.generalTerms.lake_resort",
  },
  [ID_DIERENBOS]: {
    park: "resort.dierenbos",
    recron: "form.termsConditions.recronUrl.dierenbos",
    exploitatie: "resort.dierenbos",
    parkreglement: "form.termsConditions.parkreglementUrl.dierenbos",
    conditions: "form.termsConditions.linkdierenbos",
  },
};

const GeneralTermsCheckbox = ({
  input,
  meta: { touched, error, form },
  block,
  manageTags,
}) => {
  const resort = Router.query.resort;
  const variation = customerGeneralTermsVariations[resort];

  if (!variation) return null;

  return (
    <div className="form-check" {...manageTags}>
      <label className="custom-control custom-checkbox">
        <input
          type="checkbox"
          id={block.id}
          name={block.name}
          value={block.title}
          onChange={(event) => input.onChange(event)}
          checked={!!input.value}
          className="custom-control-input"
        />
        <span className="custom-control-indicator" />
        {touched && error && (
          <div className="form-check has-danger text-danger form-control-feedback">
            {error}
          </div>
        )}
        <Stack direction="row" spacing={1}>
          <span>*</span>
          <Stack>
            <div>
              {trans("form.termsConditions.text1")}
              <Link
                href={trans(variation.conditions)} 
                target="_blank"
                onClick={(e) => e.preventDefault()}
                passHref
              >
                <MuiLink target="_blank">
                  <u>{trans("form.termsConditions.text2")}</u>
                </MuiLink>
              </Link>
              {trans("form.termsConditions.text3")}
              <Link  href={trans(variation.recron)} passHref>
                <MuiLink
                  target="_blank"
                >
                  <u>{trans("form.termsConditions.text4")}</u>
                </MuiLink>
              </Link>
              {trans("form.termsConditions.text5")}
              <Link href={trans(variation.parkreglement)} passHref>
                <MuiLink target="_blank">
                  <u>{trans("form.termsConditions.text6")}</u>
                </MuiLink>
              </Link>
              {trans("form.termsConditions.text7")}
              {trans(variation.park)}.
            </div>
            <div>
              {`(${trans(variation.exploitatie)} ${trans(
                "form.termsConditions.text8"
              )})`}
            </div>
          </Stack>
        </Stack>
      </label>
    </div>
  );
};

GeneralTermsCheckbox.propTypes = {
  input: PropTypes.object,
  block: PropTypes.object,
  name: PropTypes.string,
  meta: PropTypes.object,
  manageTags: PropTypes.object,
};

export default GeneralTermsCheckbox;
